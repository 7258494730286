/** @define header; weak */
.header {
  position: absolute;
  width: 100%;
  padding: 5px 0;
  z-index: 9;
}
.header__menu-fixed {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  background-color: #ffffff;
}
.header__logo a {
  display: block;
  line-height: 1;
  vertical-align: middle;
}
.header__logo .icon_svg {
  width: 145px;
  height: 40px;
  color: #ffffff;
}
@media only screen and (min-width: 1100px) {
  .header__logo .icon_svg {
    width: 225px;
    height: 60px;
  }
}
.header--grid {
  position: relative;
  display: grid;
}
.header--grid .header__contact--break {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media only screen and (max-width: 799px) {
  .header--grid {
    grid-template-areas: "contact contact language" "logo menu menu";
    grid-template-rows: 50px 1fr;
    grid-template-columns: 180px 1fr auto;
  }
  .header--grid .header__logo {
    grid-area: logo;
    align-self: center;
    margin-left: 10px;
    padding: 5px;
  }
  .header--grid .header__contact {
    grid-area: contact;
    align-self: start;
    padding: 10px;
    white-space: nowrap;
    text-align: right;
  }
  .header--grid .header__language {
    grid-area: language;
    align-self: center;
    padding: 10px;
    white-space: nowrap;
    text-align: right;
  }
  .header--grid .header__menu {
    grid-area: menu;
    justify-self: end;
    max-width: 100%;
  }
}
@media only screen and (min-width: 800px) {
  .header--grid {
    grid-template-areas: "logo contact language" "menu menu menu";
    grid-template-rows: auto auto;
    grid-template-columns: auto 1fr auto;
    padding-bottom: 70px;
  }
  .header--grid .header__logo {
    grid-area: logo;
    align-self: center;
    margin-left: 10px;
    padding: 5px;
  }
  .header--grid .header__contact {
    grid-area: contact;
    align-self: center;
    padding: 10px;
    text-align: right;
  }
  .header--grid .header__language {
    grid-area: language;
    align-self: center;
    padding: 10px;
    white-space: nowrap;
    text-align: right;
  }
  .header--grid .header__menu {
    grid-area: menu;
    justify-self: end;
    max-width: 100%;
  }
}
.header__contact--break .icon_svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  color: #000000;
}
.header__contact--break .header__contact--break {
  padding-right: 20px;
  color: #ffffff;
}
@media only screen and (max-width: 400px) {
  .header__contact--break .header__contact--break {
    border-right: none;
  }
}
@media only screen and (max-width: 400px) {
  .header__contact--break .code {
    display: none;
  }
}
.header__phone {
  color: #f7a810;
}
@media only screen and (min-width: 400px) {
  .header__phone {
    font-size: 18px;
  }
}
.header__phone .icon_phone {
  color: #f7a810;
}
@media only screen and (max-width: 899px) {
  .header__menu {
    color: #ffffff;
    font-family: montserratsemibold, arial, Helvetica, sans-serif;
  }
  .header__menu .wsdownopener {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 10px;
    cursor: pointer;
  }
  .header__menu p {
    margin-top: 5px;
    line-height: 1;
    transition: color 0.4s ease-in-out;
  }
  .header__menu svg {
    width: 40px;
    height: 24px;
    color: #ffffff;
  }
}
.header__menu:hover svg,
.header__menu:hover p {
  color: #f7a810;
}
.header__contact--social {
  padding: 0 20px;
  /*@media only screen and (min-width: 400px) {
    margin-right: 0;
  }*/
  border-right: 1px solid #f7a810;
  border-left: 1px solid #f7a810;
}
@media only screen and (max-width: 580px) {
  .header__contact--social {
    padding: 0 5px 0 10px;
  }
}
@media only screen and (max-width: 580px) {
  .header__contact--social {
    border-right: none;
  }
}
.header__contact--link {
  padding: 0 10px;
  line-height: 1;
  vertical-align: middle;
}
.header__contact--link:hover {
  text-decoration: none;
}
.header__contact--link:hover .icon_svg {
  color: #ffffff;
}
.header__contact--link .icon_svg {
  width: 24px;
  height: 24px;
  margin-right: 0;
  color: #f7a810;
}
@media only screen and (min-width: 400px) {
  .header__contact--link .icon_svg {
    width: 26px;
    height: 26px;
  }
}
.header__contact--link span {
  display: none;
}
.header__language {
  font-size: 0;
}
@media only screen and (max-width: 580px) {
  .header__language {
    display: none;
  }
}
.header__language .item {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin: 0 4px;
  color: #000000;
  font-size: 15px;
  line-height: 34px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  background-color: #f7a810;
  border-radius: 50%;
  transition: background-color 0.4s ease-in-out;
}
.header__language .item:hover {
  background-color: #ffffff;
}
/*# sourceMappingURL=css/header.css.map */